.inputContainer {
  position: relative;
  width: 100%;
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 58px;
  padding: 12px 12px 12px 12px;
  border: 1px solid #ccc;
  border-radius: 16px;
  font-size: 16px;
  outline: none;
  position: relative;
}

.label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #999;
  font-size: 16px;
  transition: 0.2s ease all;
  pointer-events: none;
  padding: 0 4px;
}

.shrink {
  top: 12px;
  left: 8px;
  font-size: 12px;
}
