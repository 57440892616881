.check-form {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.check-form button {
  align-items: center;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 550;
  height: 56px;
  justify-content: center;
  letter-spacing: 0.5px;
  border-radius: 16px;
  background: var(--Blue-default, #157FFF);
}
  .input-wrapper {
    background-color: #fff !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;

    label {
      color: #999;
      background-color: initial;
      font-size: 12px;
      left: 4px;
      padding: 0 .5rem;
      position: absolute;
      top: 20px;
      transform: translateY(-80%);
    }

    input:focus {
      border: 1px solid #157fff;
      outline: none;
    }
  }

  .input-wrapper input {
    height: 56px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-left: 10px;
    padding-top: 10px;
    //width: 100%;
    //max-width: 400px;
    border-radius: 16px;
    border: 1px solid #ccc;
    background: var(--Background-White, #FFF);
  }

.input-wrapper select {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 10px; /* Combines padding-left and padding-top */
  width: 100%;
  height: 56px;
}

.input-wrapper .error {
  border: 1px solid red;
}

.red {
  color: red !important;
}

.error_msg {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: red;
  margin-bottom: 5px;
  width: 100%;
}

.err-position {
  position: absolute;
  bottom: -25px;
  left: 0;
}

.uae-enter-car-form {
  .error_msg {
    font-size: 10px !important;
  }
}

.welcome_div {
  color: #818898;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .012em;
  line-height: 28px;
  text-align: center;
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 125%;
  letter-spacing: 0.012em;
}

.message_success {
  color: #3c763d;
  background-color: #dff0d8;
  margin-top: 8px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.message_error {
  color: #a94442;
  background-color: #f2dede;
  margin-top: 8px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 500px;
}

.promoCode-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.promoCode-container input {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 10px;
  width: 100%;
  height: 56px;
}

.promoCode-container button {
  background: #ddd;
  font-size: 16px;
  width: calc(24% - 9px);
  border: none;
  cursor: pointer;
  height: 56px;
}

.promoCode-container label {
  position: absolute;
  top: 16px;
  left: 4px;
  padding: 0 0.5rem;
  background-color: transparent;
  color: #999;
  font-size: 12px;
  transform: translateY(-50%);
}