.welcome_div {
  color: #b0b5d0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 125%;
  letter-spacing: 0.012em;
}

.check-form {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  padding: 15px;

}

.input-wrapper {
  background-color: #fff !important;
  /*border: 1px solid #E9E9E9;*/
  /*border-radius: 18px;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /*margin: 10px 0;*/
  position: relative;
}
.input-wrapper input {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
}
.input-wrapper select {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 10px; /* Combines padding-left and padding-top */
  width: 100%;
  height: 56px;
}
.input-wrapper .error {
  border: 1px solid red;
}
.red {
  color: red !important;
}
.error_msg {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: red;
  margin-bottom: 5px;
  width: 100%;
}
.pt-0 {
  padding-top: 0 !important;
}
.input-wrapper label {
  position: absolute;
  top: 16px;
  left: 4px;
  padding: 0 0.5rem;
  background-color: transparent;
  color: #999;
  font-size: 12px;
  transform: translateY(-50%);
}
.form-title {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}
.welcome_div {
  color: #B0B5D0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 125%;
  letter-spacing: 0.012em;
}

.message_success {
  color: #3c763d;
  background-color: #dff0d8;
  margin-top: 8px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.message_error {
  color: #a94442;
  background-color: #f2dede;
  margin-top: 8px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 500px;
}

.promoCode-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.promoCode-container input {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 10px;
  width: 100%;
  height: 56px;
}

.promoCode-container button {
  background: #ddd;
  font-size: 16px;
  width: calc(24% - 9px);
  border: none;
  cursor: pointer;
  height: 56px;
}

.promoCode-container label {
  position: absolute;
  top: 16px;
  left: 4px;
  padding: 0 0.5rem;
  background-color: transparent;
  color: #999;
  font-size: 12px;
  transform: translateY(-50%);
}
.btn-err{
  margin-top: 24px;
}

@media only screen and (max-width: 580px) {
  .message_error {
    width: 350px;
  }
}
@media only screen and (max-width: 420px) {
  .message_error {
    width: 300px;
  }
}
@media (max-width: 385px) {
  .btn-err{
    margin-top: 40px !important;
  }

}
@media only screen and (max-width: 380px) {
  .message_error {
    width: 300px;
  }
}

@media only screen and (max-width: 580px) {
  .check-form {
    width: 400px;
  }

  .message_error {
    width: 350px;
  }
}
@media only screen and (max-width: 480px) {
  .check-form {
    width: 350px;
  }
}
@media only screen and (max-width: 420px) {
  .check-form {
    width: 320px;
  }
}
@media only screen and (max-width: 380px) {
  .check-form {
    width: 300px;
  }
  .message_error {
    width: 300px;
  }
}