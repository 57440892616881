
.rightGroup {
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1000;
}

.leftGroup {
  position: fixed;
  bottom: 0;
  z-index: -1000;
  left: 0;

}

.bottomGroup {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1000;
}

@media (max-width: 768px) {
  .leftGroup {
    bottom: -10% !important;
  }
  .rightGroup{
    top: -10%;
  }
}