/* SwitchButton.css */
.switch {
    position: relative;
    display: inline-block;
    width: 60px; /* Modify as per actual size */
    height: 25px; /* Modify as per actual size */
    margin: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Inactive color */
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19px; /* Modify as per actual size */
    width: 20px; /* Modify as per actual size */
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3; /* Active color */
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px); /* Modify as per actual size */
}

/* For browsers like IE */
.slider:after {
    content: "";
    position: absolute;
    display: none;
}

input:checked + .slider:after {
    display: block;
}

/* Style for disabled switch */
input:disabled + .slider {
    background-color: #e6e6e6;
}

input:disabled + .slider:before {
    background-color: #bdbdbd;
}
@media (max-width: 768px) {
    .switch{
        width: 60px;
        height: 27px;
    }
    .slider:before{
        height: 23px; /* Modify as per actual size */
        width: 23px; /* Modify as per actual size */
        left: 2px;
        bottom: 2px;
    }
    input:checked + .slider:before {
        transform: translateX(18px); /* Modify as per actual size */
    }
}