.input-wrapper {
    background-color: #fff !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 1px;
    position: relative;

  input {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
  }

  select {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 400;
    height: 56px;
    line-height: 19px;
    padding: 10px;
  }

  label {
    background-color: initial;
    color: #999;
    font-size: 12px;
    left: 4px;
    padding: 0 .5rem;
    position: absolute;
    top: 13px;
    transform: translateY(-50%);
  }
}

.uae-enter-car-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;

  .select-region-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span {
      color: #666d80;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    select {
      border-radius: 16px;
      border: 1px solid #dfe1e7;
      color: #051c3f;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.192px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: #fff url("../../../assets/uae-icons/select-icon.svg")
        no-repeat;
      background-position-x: 96%;
      background-position-y: 16px;
    }
  }

  .select-region {
    width: 100%;
    display: flex;
    padding: 18px 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .uae-input-wrapper {
    position: relative;
    background-color: #fff !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 1px;
    flex: 1;

    input {
        display: flex;
        padding: 18px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        align-self: stretch;
        border-radius: 16px;
        border: 1px solid var(--Neutral-Solid-100, #dfe1e7);
        background: var(--White, #fff);
      }
  }

  .select-code-container {
    display: flex;
    gap: 16px;
    width: 100%;
    position: relative;
    /*margin-top: 14px;*/
  }

  .plate-code {
    flex: 1;
    width: 100%;
  }

  .uae-plate-number {
    flex: 1;
  }

  .error_msg {
    font-size: 10px !important;
  }
} 

.reverse {
  flex-direction: row-reverse;
}
