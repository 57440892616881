@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  font-variation-settings: "slnt" 0;
  overflow-y: auto;
  background: #F8FAFB;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

svg {
  flex-shrink: 0;
}

.middle{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.input-wrapper input:focus{
  border: 1px solid #157FFF;
  outline: none;
}

input {
  padding: 9px 16px;
}

input:focus, select:focus {
  outline: 1px solid #157fff;
}

.all-rights-reserved{
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%);
  color: #818898;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

select {
  padding: 9px 16px;

}

.slick-dots {
  bottom: -40px !important;
}

.slick-dots li {
  width: 5px;
}

.row {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}
.center {
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.row {
  display: flex;
}
.col {
  display: flex;
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
}

.bg-primary {
  background-color: #1c74da !important;
}

.bg-secondary {
  background-color: #9dcaf8 !important;
}

.bg-blur {
  background-color: #f2f4f7 !important;
}

code {
  font-family: "Inter", sans-serif;
}

.container {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/

  /*position: absolute;*/
  /*top: 45%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
}

.welcome_div {
  color: #b0b5d0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 125%;
  /* or 26px */

  text-align: center;
  letter-spacing: 0.012em;
}

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10%;
}

#iframes {
  border: 1px solid white;
  padding: 15px;
  border-radius: 16px;
  background: white;
}

#iframesCardCvv,
#iframesCardExpiration,
#iframesCardNumber {
  margin-top: 10px;
}
/*#iframesSubmit iframe:first-of-type {*/
/*  display: none;*/
/*}*/
#iframesCardNumber div {
  display: none !important;
}
#heartland-frame-submit {
  height: 51px;
}

.CarPaymentForm__parent_div {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: fit-content;
  border: solid 1.5px white;
  border-radius: 12px;
  color: rgb(255, 255, 255);
}

.CarPaymentForm__arrow_icon {
  position: relative;
  top: 48px;
  left: 30px;
  cursor: pointer;
  width: fit-content;
}

.CarPaymentForm__twoColumns_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}

.CarPaymentForm__column_div {
  display: flex;
  flex-direction: column;
}

.CarPaymentForm__content_div {
  display: flex;
  flex-direction: column;
  margin: 32px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.CarPaymentForm_content_margin {
  margin-top: 20px;
}

.CarPaymentForm_column_margin {
  margin-top: 10px;
}

.CarPaymentForm__text_color {
  color: red;
}

.CarPaymentFrom__fullWidth_div {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -10px;
}

.logo-img {
  height: 60px;
  width: 150px;
}

.column_div {
  display: flex;
  flex-direction: column;
}

.CarPaymentForm__label_fonts {
  font-size: 16px;
}

.CarPaymentForm__content_fonts {
  font-size: 18px;
  font-weight: 500;
}

.CarPaymentForm__content_fonts_big {
  font-size: 28px;
  font-weight: 700;
}

.border_div {
  width: 100%;
  border-bottom: solid 1px white;
}

.green-color {
  color: rgb(72, 255, 0);
}

.orange-color {
  color: orange;
}

.payment-methods-size {
  font-size: 20px;
  font-weight: 500;
}

.CarPaymentForm__card_btn {
  width: 100%;
}

.btns-between {
  margin-top: 20px;
}

/*-----------RESPONSIVE CSS--------------*/

@media screen and (orientation: landscape) and (max-height: 460px) {
  .btns-between {
    margin-top: 0px;
    margin-left: 57px;
  }
  .CarPaymentForm__card_btn {
    width: 45%;
  }
  .CarPaymentForm__parent_div {
    width: 650px;
    height: fit-content;
  }

  .CardPaymentForm_btns_row_div {
    display: flex;
    flex-direction: row;
  }

  .CarPaymentForm__content_div {
    display: flex;
    flex-direction: column;
    margin: 32px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .CarPaymentForm_content_margin {
    margin-top: 10px;
  }

  .CarPaymentForm_column_margin {
    margin-top: 5px;
  }

  .CarPaymentFrom__fullWidth_div {
    margin-top: -10px;
  }

  .CarPaymentForm__arrow_icon {
    top: 36px;
  }
}

@media screen and (orientation: landscape) and (max-height: 360px) {
  .CarPaymentForm_content_margin {
    margin-top: 10px;
  }

  .CarPaymentForm_column_margin {
    margin-top: 5px;
  }

  .CarPaymentForm__label_fonts {
    font-size: 12px;
  }
  .CarPaymentForm__content_fonts {
    font-size: 12px;
  }
  .CarPaymentForm__content_fonts_big {
    font-size: 18px;
  }
  .payment-methods-size {
    font-size: 16px;
  }
  .logo-img {
    height: 40px;
    width: 100px;
  }
  .CarPaymentForm__arrow_icon {
    top: 16px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .CarPaymentForm__content_div {
    margin-left: 10px;
    margin-right: 10px;
  }
  .CarPaymentForm__parent_div {
    width: 95%;
  }
  .CarPaymentForm__arrow_icon {
    font-size: 26px;
    top: 26px;
  }
}

@media only screen and (max-width: 400px) {
  .CarPaymentForm__label_fonts {
    font-size: 12px;
  }
  .CarPaymentForm__content_fonts {
    font-size: 14px;
  }
  .CarPaymentForm__content_fonts_big {
    font-size: 22px;
  }
}
@media only screen and (max-width: 320px) {
  .CarPaymentForm__arrow_icon {
    left: 5px;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #157fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PaymentSuccess__parent_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: none;
  background: white;
  width: 400px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 440px) {
  .PaymentSuccess__parent_div {
    width: 300px;
  }
}

@media only screen and (max-width: 768px) {

}
.PaymentSuccess__checkMark {
  color: green;
  font-size: 40px;
}

.PaymentSuccess__text {
  color: green;
  font-size: 34px;
  width: 100%;
  text-align: center;
}

.PaymentSuccess__cross {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.PaymentSuccess__cross_position {
  position: relative;
  right: 20px;
  top: -20px;
  font-size: 10px;
  cursor: pointer;
}
.red {
  color: red !important;
}

.email_field {
  font-family: sans-serif;
  box-sizing: border-box;
  display: block;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
  -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 300px;
  margin-top: 10px;
}
.email_input_container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.email_field {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.012em;

  /* On Surface secondary */

  color: #b0b5d0;
}

.email_field.error {
  border: 1px solid #f44336;
}

.error_toolbar {
  display: flex;
  align-items: center;
  background-color: #f44336;
  color: white;
  position: absolute;
  bottom: 73%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  border-radius: 4px;
}

.error_toolbar::after {
  content: "";
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #f44336 transparent;
}
.check {
  border: 1px solid red !important;
  border-radius: 18px;
  height: 58px;
}
#heartland-frame-cardNumber,
#heartland-frame-cardExpiration,
#heartland-frame-cardCvv,
.email_field {
  width: 100%;
  border: 1px solid #e9e9e9 !important;
  border-radius: 18px;
  height: 56px !important;
}
#heartland-frame-submit {
  width: 100%;
  height: 64px !important;
}

.responsive_width {
  width: calc(100% - -25px) !important;
}
.email_description {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.012em;
  margin-top: 5px;
  /* On Surface secondary */

  color: #b0b5d0;
}
.error_msg {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* Error */

  color: #ec1c24;
}
.btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  height: 56px;
  background: #157fff;
  border-radius: 16px;
  border: none;
}
.fn-16px {
  font-size: 16px;
}


.lds-spinner {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.text-loud{
  color: #0D0D12;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.text-disabled{
  color:#A4ACB9;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.slick-track{
  margin: 0;
}

.text-normal{
  color: #666D80;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.text-muted{
  color: #36394A;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
}
.btn-none{
  background: none !important;
  border: none !important;
}
.rotate{
  transform: rotate(180deg);
}
.cus-button{
  border: none;
  background: none;
}
.text-content{
  color: #264D99;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.24px;
}

.slick-track {
  width: 100%!important;
  display: flex;
}

.slick-slide {
  width: fit-content!important;
  margin-right: 20px;
}
.slick-list {

}