.check-form {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    padding: 15px;
}

.input-wrapper {
    background-color: #fff !important;
    /*border: 1px solid #E9E9E9;*/
    /*border-radius: 18px;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /*margin: 10px 0;*/
    position: relative;
}
.input-wrapper input {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
}
.input-wrapper select {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 10px; /* Combines padding-left and padding-top */
    width: 100%;
    height: 56px;
}
.input-wrapper .error {
    border: 1px solid red;
}
.red {
    color: red !important;
}
.error_msg {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: red;
    margin-bottom: 5px;
    width: 100%;
}
.pt-0 {
    padding-top: 0 !important;
}
.input-wrapper label {
    position: absolute;
    top: 16px;
    left: 4px;
    padding: 0 0.5rem;
    background-color: transparent;
    color: #999;
    font-size: 12px;
    transform: translateY(-50%);
}
.form-title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
}
.welcome_div {
    color: #B0B5D0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 16px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 125%;
    letter-spacing: 0.012em;
}

.message_success {
    color: #3c763d;
    background-color: #dff0d8;
    margin-top: 8px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.message_error {
    color: #a94442;
    background-color: #f2dede;
    margin-top: 8px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 500px;
}

.promoCode-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.promoCode-container input {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-left: 10px;
    width: 100%;
    height: 56px;
}

.promoCode-container button {
    background: #ddd;
    font-size: 16px;
    width: calc(24% - 9px);
    border: none;
    cursor: pointer;
    height: 56px;
}

.promoCode-container label {
    position: absolute;
    top: 16px;
    left: 4px;
    padding: 0 0.5rem;
    background-color: transparent;
    color: #999;
    font-size: 12px;
    transform: translateY(-50%);
}
.btn-err{
    margin-top: 24px;
}

@media only screen and (max-width: 580px) {
    .message_error {
        width: 350px;
    }
}
@media only screen and (max-width: 420px) {
    .message_error {
        width: 300px;
    }
}
@media (max-width: 385px) {
    .btn-err{
        margin-top: 40px !important;
    }

}
@media only screen and (max-width: 380px) {
    .message_error {
        width: 300px;
    }
}

@media only screen and (max-width: 580px) {
    .check-form {
        width: 400px;
    }

    .message_error {
        width: 350px;
    }
}
@media only screen and (max-width: 480px) {
    .check-form {
        width: 350px;
    }
}
@media only screen and (max-width: 420px) {
    .check-form {
        width: 320px;
    }
}
@media only screen and (max-width: 380px) {
    .check-form {
        width: 300px;
    }
    .message_error {
        width: 300px;
    }
}





.infoCar_name{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin: 10px 0 !important;
}
.infoCar_address{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #ADB9C9;
    letter-spacing: 0.012em;
}
.infoCar_info{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E9E9E9;
    padding: 15px 0;
}
.infoCar_info span:first-of-type{
    color: #ADB9C9;
}
.highlighted{
    color: #157FFF !important;
    font-weight: 700 !important;
}
.infoCar_button{
    align-items: center;
    background-color: #1c74da;
    border: none;
    border-radius: 18px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 550;
    height: 56px;
    justify-content: center;
    letter-spacing: .5px;
    width: 100%;
    margin-top: 30px;
}
.infoCar_additional_info{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.012em;
    margin-bottom: 10px;
    color: #B0B5D0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.paymentContainer {
    display: flex;
    gap: 10px;
}
.payment-type{
    cursor: pointer;
    display: flex;
    padding: 10px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    color: #051C3F;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.168px;
    border: 1px solid #E9E9E9;
    background: #FFF;
}
.active{
    background: #157FFF !important;
    color: #FFF !important;
}
.plans{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.plan{
    cursor: pointer;
    display: flex !important;
    padding: 10px 12px;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #E9E9E9;
}
.info-wrapper{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.subscription-info-wrapper{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 24px 0;
}
.plan span:first-child {
    color: #051C3F;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.192px;
}

.plan span:nth-child(2) {
    color: #157FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.144px;
}
.plan-active{
    background: #157FFF !important;
    color: #FFF;
}
.plan-active span{
    color: #FFF !important;
}
.info-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subdued{
    color: #B0B5D0;
    font-size: 16px;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.168px;
}
.value{
    color: #051C3F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.168px;
}
.amount{
    color: #157FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.168px;
}
.unpaid-session{
    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #E9E9E9;
    background: #FFF;
}
.unpaid-description{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.unpaid-info{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}
.date-wrapper{
    display: flex;
    flex-direction: column;
    gap: 14px;
    color: #051C3F;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.192px;
}
.period-days{
    /*text-overflow: ellipsis;*/
    white-space: nowrap;
    /*overflow: hidden;*/
}
.select-inner-parkings{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #051C3F;
    /* Headline/Headline 4 */
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.192px;
}
.select-inner-parkings select {
    width: 100%;
    padding: 10px;
    background: #FFF;
    border: 1px solid #959da5;
    border-radius: 8px;
    -webkit-appearance: none; /* Remove default styling on iOS */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    font-family: sans-serif; /* Example font, change as needed */
}
.session-option{
    display: flex;
    justify-content: space-between;
    gap: 5px;
}
.current-session{
    display: flex;
    padding: 10px 16px 10px 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #FFF;
    cursor: pointer;
    color: #051C3F;
    border: 1px solid #E9E9E9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.168px;
}
.include-section{
    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #E9E9E9;
    background: #FFF;
}
.switch-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.infoCar_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

}
.text-normal{
    color:#666D80;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.carousel-item-padding-5-px {
    padding-right: 20px;
}

.react-multi-carousel-item {
    width: 100% !important;
    margin-right: 20px;
    user-select: none;
}

.react-multi-carousel-dot-list {
    overflow-x: clip;
}